.footer {
  padding: 100px 0;
  background-color: #FDC926;
  margin-top: 150px;

  &-input {
    &-section .section-outer .container {
      overflow: visible;
    }
  }

  & .section {
    margin-top: 0;
  }

  &-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 540px;

    &-container {
      display: flex;
      justify-content: flex-end;
    }

    &-button {
      background-color: #2B2A29;
      padding: 20px 60px;
      font-size: 16px;
      font-weight: 400;
      margin-right: 25px;
      cursor: pointer;
      transition: 0.25s;
      border: 1px solid transparent;
      position: relative;
      min-height: 60px;
      min-width: 160px;

      &:hover {
        background-color: transparent;
        border: 1px solid #2B2A29;

        & span {
          color: #2B2A29;
        }
        & #loading{
          border: 3px solid rgba(0, 0, 0, .3);
          border-top-color: #000;
        }
      }

      & div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      & span{
        color: #ffffff;
      }
    }

    & .input-valid {
      width: 100%;

      input {
        background-color: transparent;
        margin-bottom: 20px;
        width: 100%;
        padding: 20px;
        font-size: 16px;
        border: 1px solid #2B2A29;
        transition: 0.5s;
        box-sizing: border-box;

        &::placeholder {
          color: #2B2A29;
          opacity: 0.5;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
          outline: none;

          &::placeholder {
            color: transparent;
          }
        }
      }
    }
  }

  &-bottom {
    background-color: #2B2A29;
    padding: 50px 0 30px;
    color: #fff;

    &-link{
      text-decoration: none;
      transition: 0.3s;
      &:hover{
        color: #FDC926;
      }
    }

    &-links {
      width: 65%;
      display: flex;
      justify-content: space-between;
    }

    &-item {
      max-width: 230px;
      display: flex;
      flex-direction: column;

      & a {
        margin-bottom: 8px;
      }
    }

    &-title {
      color: #878787;
    }

    & div, a {
      color: #fff;
    }

    & .section {
      margin-top: 0;
    }
  }

  &-logo {
    width: 35%;

    & img {
      object-fit: contain;
    }
  }

  &-rights {
    &-links a {
      margin-left: 30px;
      text-decoration: none;

      &:hover {
        color: #FDC926;
      }
    }

    &-container {
      width: 100%;
      border-top: 1px solid #555555;
      margin-top: 50px;
      padding: 30px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & p {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.form{
  &-captcha{
    margin-top: 0;
    & a{
      margin: 0 3px;
    }
  }
}

.grecaptcha-badge { visibility: hidden; }

.input{
  &-submit{
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
}

#loading {
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 3px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    &-items {
      & .section-flex {
        display: flex;
        flex-direction: column;
      }
    }

    &-bottom {
      &-links {
        width: 100%;
        margin-top: 50px;
        flex-wrap: wrap;

        & .footer-bottom-item {
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer{
    &-inputs{
      max-width: 100%;
      &-button{
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .footer {
    &-rights-container {
      padding-top: 0;
      border-top: none;
      flex-direction: column;
      align-items: flex-start;
    }

    &-rights-links {
      order: 1;
      margin-bottom: 50px;

      & a {
        margin-left: 0;
        margin-right: 30px;
      }
    }

    &-rights-stamp {
      order: 2;
      width: 100%;
      border-top: 1px solid #555555;
      padding-top: 30px;
      font-size: 16px;
      text-align: center;
    }
  }
}