.reviews{
  &-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &-item{
    max-width: 45%;
    margin-bottom: 35px;
    & p{
      margin: 0;
    }
  }
  &-message{
    font-weight: 500;
    font-style: italic;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px!important;
  }
  &-author{
    font-weight: 500;
    margin-bottom: 8px!important;
  }
  &-position{
    color: #878787;
    font-weight: 400;
  }
  &-quote{
    max-width: 32px;
    margin-bottom: 23.5px;
  }
}

@media screen and (max-width: 500px){
  .reviews{
    &-item{
      max-width: 100%;
    }
  }
}