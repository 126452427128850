.faq{
  &-row{
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }
  &-items-container{
    width: 100%;
    & .faq-row-wrapper{
      width: 100%;
      font-weight: 500;
    }
    & .row-title{
      padding: 40px 10px 40px 0!important;
      user-select: none;
      -webkit-user-select: none;
      & span{
        position: inherit!important;
        font-size: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        font-weight: 400;
        -webkit-user-select: none;
      }
    }
    & .row-content-text{
      font-weight: 400;
      line-height: 1.4;
    }
    & .row-title-text{
      font-size: 16px;
    }
    & .faq-row:first-child{
      border-top: 1px solid #ccc;
    }
  }
}