* {
  font-family: "Roboto", sans-serif;
  color: #2B2A29;
  font-size: 16px;
  box-sizing: border-box;
}

h2 {
  font-size: 32px;
  font-weight: 500;
}

body {
  margin: 0;
}

.main {
  &-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    min-height: 1000px;

    &-inner {
      width: 100%;
    }
  }
}

.container {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
