.not-found {
  &-code {
    font-weight: 600;
    font-size: 160px;
    line-height: 1;
  }

  &-page {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &-content {
    font-size: 28px;
    margin: 10px 0 25px;
  }
  &-home{
    font-size: 28px;
    font-weight: 500;
  }
}