.loader {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 150;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-logo {
    max-width: 170px;
    max-height: 170px;
  }

  &-circle {
    animation: loader 2s linear infinite;
    animation-fill-mode: forwards;
    transform-origin: center;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 480px) {
  .loader{
    &-logo{
      max-width: 100px;
      max-height: 100px;
    }
  }
}