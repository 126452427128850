.burger {
  &-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #FDC926;
    z-index: 105;
    transition: 0.3s;
    transform: translateX(100%);

    &-active {
      transform: translateX(0);
    }
  }

  &-close {
    & svg {
      width: 30px;
    }
  }

  &-menu {
    display: inline-block;
    flex-direction: column;
    padding: 0 20px;

    & li {
      list-style: none;
      margin-bottom: 20px;

      &:last-child {
        border: 1px solid #000;
        padding: 20px 40px;
        width: auto;

        & a {
          font-size: 18px;
        }
      }
    }

    & a {
      text-decoration: none;
      font-size: 26px;
      font-weight: 400;
    }
  }
  &-nav{
    width: 100%;
  }

  &-button {
    z-index: 101;
    position: relative;
    display: none;

    & svg {
      max-width: 35px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .burger {
    &-button {
      display: block;
    }
  }
}