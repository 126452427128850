.table {
  margin: 0 30px;

  &-section {
    margin-top: 100px;
    & .section-body{
      overflow: visible;
    }
  }

  &-row {
    display: flex;
    padding: 30px 20px;
    border-bottom: 1px solid #D5D4D4;

    &:first-of-type {
      border-top: 1px solid #D5D4D4;
    }
  }

  &-col {
    margin: 0;
    font-weight: 500;
  }

  &-name {
    width: 60%;
  }

  &-value {
    width: 40%;
    color: #878787;
  }

  &-container {
    display: flex;
    width: 100%;

    & .table {
      width: 100%;
    }
  }
}

@media screen and (max-width: 900px) {
  .table{
    margin: 0 0 50px;
    &-section{
      margin-top: 0;
    }
    &-row{
      padding-left: 0;
      padding-right: 0;
    }

    & h2{
      margin-bottom: 40px;
    }

    &-container{
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 480px) {
  .table{
    &-col{
      & .tooltip{
        display: none;
      }
    }
  }
}