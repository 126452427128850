.hero-banner {
  &-text {
    font-weight: 700;
    font-size: 50px;
    margin: 0;
    line-height: 65px;
    & span{
      font-weight: 700;
      font-size: 50px;
      line-height: 65px;
    }
    & .slogan{
      color: #FDC926;
    }

    &-container {
      margin-top: 200px;
      display: flex;
      justify-content: center;
    }

    &-inner {
      margin-bottom: 85px;
    }
  }

  &-image {
    width: 100%;

    & img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1260px) {
  .hero-banner {
    &-text-inner {
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-banner {
    &-text {
      font-size: 36px;
      line-height: 1.2;
      font-weight: 500;
      & span{
        font-size: 36px;
        line-height: 1.2;
        font-weight: 500;
      }
      &-container {
        margin-top: 10px;
      }

      &-inner {
        margin-bottom: 40px;
      }
    }

    &-image {
      height: 300px;

      & img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}