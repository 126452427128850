.tooltip {
  display: inline;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
  top: 5px;

  &-container {
    position: absolute;
    left: -7.5px;
    top: calc(100% + 15px);
    line-height: 20px;
    padding: 10px;
    font-size: 14px;
    background: white;
    width: 250px;
    border: 1px solid #FDC926;
    font-weight: 400;
    z-index: 5;
    cursor: default;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #FDC926 transparent;
      top: -20px;
      left: 10px;
      z-index: 1;
    }

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent white transparent;
      top: -19px;
      left: 10px;
      z-index: 2;
    }
  }
}

.alert-enter {
  opacity: 0;
}

.alert-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
