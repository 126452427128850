.section {
  width: 100%;
  margin-top: 200px;

  &-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-heading {
    margin: 18px 0;
  }

  &-left {
    width: 360px;
    line-height: 1.5;
    margin-right: 100px;
  }

  &-right {
    max-width: 740px;
    width: 100%;
    flex-wrap: wrap;
  }

  &-flex-title {
    margin-top: 0;
  }

  &-flex {
    display: flex;
  }
}

@media screen and (max-width: 1250px) {
  .section {
    &-outer {
      padding: 0 20px;
    }

    &-right {
      max-width: 60%;
    }

    &-left {
      max-width: 40%;
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .section {
    margin-top: 90px;
    &-outer{
      padding: 0 15px;
    }
    &-flex{
      flex-direction: column;
    }

    &-right{
      max-width: 100%;
    }

    &-left{
      width: 100%;
      max-width: 100%;
    }
  }
}