.products-slider {
  & .slick-list {
    outline: none;
    margin-right: -50px;

    &:focus {
      outline: none;
    }
  }

  &-name {
    color: #000000;
  }

  &-item {
    width: 100%;
    max-width: 255px;
    text-decoration: none;
    position: relative;

    &-image {
      background-color: #F5F5F5;
      width: 100%;
      height: 100%;
      min-height: 255px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 50px;
      box-sizing: border-box;
      & img{
        width: 100%;
      }
      &:before{
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;
        width: 100%;
        height: 100%;
        transition: 0.3s;
      }
      &:hover::before{
        opacity: 1;
      }

    }
  }

  &-arrows {
    display: flex;
    justify-content: flex-end;

    & svg {
      cursor: pointer;
    }

    & svg:first-child {
      margin-right: 38px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .products-slider {
    & .slick-list {
      margin-right: -3%;
    }

    &-item {
      max-width: 90%;

      &-image {
        min-height: 210px;

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .products-slider {
    & .slick-list {
      margin-right: -5%;
    }

    &-item {
      max-width: 90%;

      &-image {
        min-height: 280px;

      }
    }
  }
}

@media screen and (max-width: 600px) {
  .products-slider {
    & .slick-list {
      margin-right: 0;
    }

    &-item {
      max-width: 100%;

      &-image {
        min-height: 350px;

      }
    }
  }
}