.about-company {
  display: flex;

  &-description {
    margin-bottom: 50px;
  }

  &-advantages {
    font-weight: 500;
    position: relative;
    padding-left: 20px;
    margin-top: 30px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: #FDC926;
    }
  }

  &-statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 62px;
    margin-right: -30px;

    &-item {
      width: 28%;
      margin-bottom: 50px;
    }

    &-value {
      font-size: 60px;
      font-weight: 500;
      position: relative;

      &::before {
        top: -12px;
        margin-bottom: 12px;
        content: '';
        position: absolute;
        width: 205px;
        height: 2px;
        background-color: #FDC926;
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .about-company {
    &-statistics {
      &-item {
        width: 45%;
        min-width: 250px;
        margin-bottom: 50px;
        margin-right: 15px;
      }

      &-value {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-company {
    &-content {
      display: flex;
      margin-bottom: 50px;
    }

    &-description {
      max-width: 50%;
      margin-right: 40px;
      margin-top: 0;
    }

    &-advantages {
      &-list {
        max-width: 50%;

        & .about-company-advantages:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .about-company {
    &-content {
      flex-direction: column;
    }

    &-description {
      max-width: 100%;
    }

    &-statistics {
      &-item {
        width: 100%;
      }
    }
  }
}