.certificate{
  &-item{
    max-width: 45%;
    margin-bottom: 25px;
    & img{
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px){
  .certificate{
    &-item{
      max-width: 100%;
      width: 100%;
      margin-bottom: 25px;
      & img{
        width: 100%;
      }
    }
  }
}