.product {
  &-container {
    margin-top: 100px !important;
  }

  &-information {
    width: 100%;
    display: flex;

    &-title {
      margin: 0;
    }

    &-paragraph {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #D5D4D4;
    }

    &-content {
      margin-left: 30px;
      width: 50%;
      max-width: 600px;
      padding-right: 20px;
      font-weight: 400;
      line-height: 1.5;
    }

    &-image {
      background-color: #F5F5F5;
      min-height: 500px;
      max-width: calc(50% - 60px);
      width: 50%;
      position: relative;
      margin-right: 30px;
      display: flex;
      align-items: center;

      & img {
        position: absolute;
        right: 100px;
        width: 100%;
        height: 100%;
        max-width: 300px;
        object-fit: contain;
        //max-height: 255px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .product {
    &-information-image {
      & img {
        right: 65px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .product {
    &-information {
      flex-direction: column;
      padding: 0 20px;
      &-content{
        margin-left: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 40px;
      }
      &-image{
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        & img{
          width: 100%;
          position: inherit;
          right: auto;
        }
      }
    }
  }
}