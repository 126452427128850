.big-slider {
  &-arrows {
    max-width: 1200px;
    width: 100%;

    &-container {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      user-select: none;
      -webkit-user-select: none;
    }
  }

  & .container {
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }

  &-container {
    width: 100%;

    & .slick-list {
      margin: 0 -100px;
      outline: none;
    }

    & .slick-slide {
      & div {
        margin: 0 50px;
        outline: none;
      }
    }
  }

  &-item {
    cursor: pointer;
    width: 100%;
    height: 500px;
    object-fit: contain;
    margin: 0 !important;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1260px) {
  .big-slider {
    &-arrows {
      &-container {
        & .slick-list {
          margin: 0 -50px;
          outline: none;
        }
        & .slick-slide {
          & div {
            margin: 0 25px;
            outline: none;
          }
        }
      }
    }
  }
}