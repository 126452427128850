.partners{
  &-items-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &-item{
    background-color: #FDC926;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 340px;
    margin-top: 40px;
    height: 200px;
    & img{
      max-width: 70%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1024px) {
  .partners{
    &-item{
      max-width: 45%;
      height: 150px;
    }
  }
}

@media screen and (max-width: 500px) {
  .partners{
    &-item{
      max-width: 100%;
    }
  }
}