.header {
  width: 100%;
  display: flex;
  justify-content: center;

  &-inner {
    min-height: 150px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    overflow: visible;
  }

  &-logo {
    max-width: 260px;
    & a{
      display: block;
    }
  }

  &-nav {
    list-style: none;
    display: flex;
    align-items: center;

    & a {
      margin-left: 30px;
      text-decoration: none;
      position: relative;
      &:last-child{
        border: 1px solid #fdc926;
        padding: 20px 40px;
        &::before{
          content: "";
          background-color: #FDC926;
          position: absolute;
          height: 100%;
          top: 0;
          z-index: -1;
        }
      }
      &::before{
        content: '';
        background-color: #FDC926;
        height: 2px;
        width: 0;
        position: absolute;
        bottom: -5px;
        left: 0;
        transition: 0.2s;
      }
      &:hover::before{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .header-inner {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1000px) {
  .header-nav {
    display: none;
  }
}

@media screen and (max-width: 500px){
  .header{
    &-logo{
      max-width: 200px;
    }
  }
}